import * as React from 'react';

import { navigate } from 'gatsby';

import { theme } from '../../../../styles/2020/theme';

import { Link } from '../../common/Link';
import { PageHeading, Section } from '../../common/Primitives';
import { Center } from '../../layout/Center';
import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

import ohNoImage from '../../../../../assets/images/404_ohno.svg';

export function PageNotFoundBlock() {
  return (
    <Section>
      <Column space={theme.space.l}>
        <Center max="500px">
          {/* image is decorative and does not require alt text */}
          <img src={ohNoImage} alt="" />
        </Center>

        <Column>
          <Row>
            <PageHeading size="l">We can’t seem to find the page you are looking for.</PageHeading>
          </Row>

          <Row>
            <Link
              url=""
              onClick={(ev) => {
                ev.preventDefault();
                navigate(-1);
              }}
            >
              Back
            </Link>

            <span>|</span>

            <Link url="/">Home</Link>
          </Row>
        </Column>
      </Column>
    </Section>
  );
}
