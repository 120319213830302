import * as React from 'react';

import type { PageProps } from 'gatsby';

import { PageNotFoundBlock } from 'stile-shared/src/components/2020/blocks/PageNotFoundBlock';

import { PageLayout } from 'templates/2020/PageLayout';

export function Head() {
  return <title>404 Page not found | Stile Education</title>;
}

function Error404Page(props: PageProps) {
  return (
    <PageLayout {...props}>
      <PageNotFoundBlock />
    </PageLayout>
  );
}

export default Error404Page;
